function Footer() {
    return (
      <footer>
        {/* Your footer content here */}
        {/* <p>© 2024 Aseem Chopra All rights reserved.</p> */}
        {/* <p>Connect</p> */}
        <p>Reach me at aseemchopra@protonmail.com</p>
      </footer>
    );
  }
  
  export default Footer;